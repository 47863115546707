import React, { useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'

import CreateConditionModal from '../components/tagging/Create'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import QueryVetModal from '../components/common/QueryVetModal'
import { Badge, Button } from 'reactstrap'
import { Conditions_conditions } from '../hasura/graphQlQueries/types/Conditions'
import { Table, Header, HeaderCell, TextCell, Row, NarrowCell, isColumn } from '../components/common/Table'
import { Textarea } from '../components/common/Input'
import { updateConditionAiNotesAction, updateConditionSymptomsAction } from '../hasura/slices/conditions'
import { consultationsSelector, ConsultationsState } from '../hasura/slices/consultations'
import { riskForSeverity } from '../lib/aiHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'
import { isSuperAdmin } from '../lib/helpers'

const defaultColumn: any = {
  Cell: TextCell,
}

export default function Conditions() {
  const dispatch = useDispatch()

  const { accessToken, role }: UsersState = useSelector(usersSelector)
  const { conditions }: ConsultationsState = useSelector(consultationsSelector)

  const [displayCreateConditionModal, setDisplayCreateConditionModal] = useState(false)
  const [displayQueryVetModal, setDisplayQueryVetModal] = useState(false)

  const [symptoms, setSymptoms] = useState<[number, string] | undefined>(undefined)
  const [aiNotes, setAiNotes] = useState<[number, string] | undefined>(undefined)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (c: Conditions_conditions) => c,
        sortDescFirst: true,
        sortType: (a: { original: Conditions_conditions }, b: { original: Conditions_conditions }) =>
          a.original.display_name.localeCompare(b.original.display_name),
        Cell: (c: { value: Conditions_conditions }) => (
          <p onClick={() => navigate(`/condition?id=${c.value.id}`)} className={`text-m m-0 pointer underline`}>
            {c.value.display_name}
          </p>
        ),
      },
      {
        Header: 'Species',
        accessor: 'species',
        sortDescFirst: true,
        Cell: (c: { value: string }) => (
          <p className={`text-l m-0`}>{(c.value || '').replace('dog', '🐶').replace('cat', '🐱').split(',').join(' ')}</p>
        ),
      },
      {
        Header: 'Category',
        accessor: 'category.display_name',
        sortDescFirst: false,
      },
      {
        Header: 'Severity',
        accessor: 'severity',
        sortDescFirst: true,
        Cell: (c: { value: number }) => {
          const risk = c.value ? riskForSeverity(c.value) : undefined
          return risk ? <Badge className={`text-xs min-width-100px ${risk.toLowerCase()}-risk`}>{risk} Risk</Badge> : null
        },
      },
      {
        Header: 'Symptoms',
        accessor: (c: Conditions_conditions) => c,
        sortDescFirst: true,
      },
      {
        Header: 'AI Notes',
        accessor: (c: Conditions_conditions) => c,
        sortDescFirst: true,
      },
      {
        Header: 'ML Config',
        accessor: (c: Conditions_conditions) => c,
        sortDescFirst: false,
        Cell: (c: { value: Conditions_conditions }) => {
          return (
            <div>
              <pre className="regular text-xxs m-0 max-height-150px">
                {
                  // @ts-ignore
                  c.value.ml_config ? JSON.stringify(c.value.ml_config, null, 2) : ''
                }
              </pre>
            </div>
          )
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: conditions,
      defaultColumn,
      // @ts-ignore
      initialState: { sortBy: [{ id: 'Name', desc: false }] },
    },
    useSortBy
  )

  const handleSaveSymptoms = () => {
    if (!symptoms) return

    dispatch(updateConditionSymptomsAction(accessToken, symptoms[0], symptoms[1]))
    setSymptoms(undefined)
  }

  const handleSaveAiNotes = () => {
    if (!aiNotes) return

    dispatch(updateConditionAiNotesAction(accessToken, aiNotes[0], aiNotes[1]))
    setAiNotes(undefined)
  }

  const colSpan = (data: any) => (isColumn(data, 'ML Config') ? 2 : 1)

  return (
    <Layout>
      {displayCreateConditionModal && <CreateConditionModal setDisplayCreateConditionModal={setDisplayCreateConditionModal} />}

      {displayQueryVetModal && <QueryVetModal setDisplayQueryVetModal={setDisplayQueryVetModal} />}

      <MainBox defaultPadding>
        <div className="min-height-400px">
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <div className="d-flex align-items-end">
                <h4 className="bold m-0">Conditions</h4>
              </div>
            </div>

            {isSuperAdmin(role) && (
              <div>
                <Button
                  onClick={() => setDisplayQueryVetModal(true)}
                  className="min-width-100px mr-3"
                  size="sm"
                  color="primary"
                  outline
                >
                  Ask a specialist?
                </Button>

                <Button
                  onClick={() => setDisplayCreateConditionModal(true)}
                  className="min-width-100px"
                  size="sm"
                  color="primary"
                  outline
                >
                  New+
                </Button>
              </div>
            )}
          </div>

          <Table style={{ maxWidth: '1300px' }} className="w-100" cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, idx: number) => (
                    <HeaderCell
                      key={idx}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ width: ['Symptoms', 'AI Notes'].includes(column.Header) && '300px' })
                      )}
                      colSpan={colSpan(column)}
                    >
                      {column.Header && <Header>{column.render('Header')}</Header>}
                    </HeaderCell>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)

                return (
                  <Row {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx: number) => {
                      if (cell.column.Header === 'Symptoms') {
                        const id = cell.row.original.id
                        const isEditing = symptoms && symptoms[0] === id

                        return (
                          <NarrowCell key={idx} {...cell.getCellProps()}>
                            <Textarea
                              onChange={(e: any) => setSymptoms([id, e.target.value])}
                              className="text-m m-0 w-100 d-flex p-1 my-2 min-height-80px"
                              onBlur={handleSaveSymptoms}
                              value={(isEditing ? symptoms[1] : cell.row.original.symptoms_description_short) || ''}
                            />
                          </NarrowCell>
                        )
                      }

                      if (cell.column.Header === 'AI Notes') {
                        const id = cell.row.original.id
                        const isEditing = aiNotes && aiNotes[0] === id

                        return (
                          <NarrowCell key={idx} {...cell.getCellProps()}>
                            <Textarea
                              onChange={(e: any) => setAiNotes([id, e.target.value])}
                              className="text-m m-0 w-100 d-flex p-1 my-2 min-height-80px"
                              onBlur={handleSaveAiNotes}
                              value={(isEditing ? aiNotes[1] : cell.row.original.ai_notes) || ''}
                            />
                          </NarrowCell>
                        )
                      }

                      return (
                        <NarrowCell key={idx} {...cell.getCellProps()} colSpan={colSpan(cell)}>
                          {cell.render('Cell')}
                        </NarrowCell>
                      )
                    })}
                  </Row>
                )
              })}
            </tbody>
          </Table>
        </div>
      </MainBox>
    </Layout>
  )
}
