import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import sortBy from 'lodash/sortBy'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Option, pluralize, vetNameWithoutQualifications } from '../../lib/helpers'
import { Input, Textarea } from '../common/Input'
import { UsersState, fetchVetsAction, usersSelector } from '../../hasura/slices/users'
import { insertCaseQuestionAction } from '../../hasura/slices/conditions'
import { ConsultationsState, consultationsSelector } from '../../hasura/slices/consultations'

interface Props {
  setDisplayQueryVetModal: (displayQueryVetModal: boolean) => void
}

export default function QueryVetModal(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, vets, user }: UsersState = useSelector(usersSelector)
  const { conditions }: ConsultationsState = useSelector(consultationsSelector)

  const [questionText, setQuestionText] = useState<string | undefined>()
  const [questions, setQuestions] = useState<any[]>([])
  const [selectedVet, setSelectedVet] = useState<Option | null>(null)
  const [selectedCondition, setSelectedCondition] = useState<Option | null>(null)
  const [imageName, setImageName] = useState<string | undefined>()

  useEffect(() => {
    if (vets.length || !accessToken || !user) return

    dispatch(fetchVetsAction(accessToken, user.organization.enterprise.id))
  }, [accessToken, vets, user])

  const createCaseQuestion = async () => {
    const caseQuestion = {
      vet_id: selectedVet?.value,
      condition_id: selectedCondition?.value,
      case_questions_denormalized: questions.map(({ text, imageName }) => ({ text, imageName, id: null })),
    }
    dispatch(insertCaseQuestionAction(accessToken, caseQuestion))
    props.setDisplayQueryVetModal(false)
  }

  const addQuestion = () => {
    setQuestions(questions.concat({ text: questionText, imageName }!))
    setQuestionText('')
    setImageName(undefined)
  }

  const vetOptions = vets.map((v): Option => ({ value: v.id, label: vetNameWithoutQualifications(v.display_name) }))

  const conditionOptions = sortBy(
    conditions.map((c): Option => ({ value: c.id, label: c.display_name })),
    'label'
  )

  return (
    <Modal
      fade={false}
      centered
      autoFocus={false}
      isOpen
      className="position-relative"
      toggle={() => props.setDisplayQueryVetModal(false)}
    >
      <ModalHeader>Query Vet</ModalHeader>

      <ModalBody className="p-4">
        <div className="mb-3">
          <Select
            className="max-width-250px mb-3"
            classNamePrefix="react-select"
            isClearable
            onChange={(o: Option | null) => setSelectedCondition(o)}
            options={conditionOptions}
            placeholder="Select condition (optional)"
            value={selectedCondition}
          />

          <Select
            className="max-width-250px mb-3"
            classNamePrefix="react-select"
            isClearable
            onChange={(o: Option | null) => setSelectedVet(o)}
            options={vetOptions}
            placeholder="Select specialist (optional)"
            value={selectedVet}
          />

          {questions.map((q, i) => (
            <p className="m-0" key={i}>
              {i + 1}) {q.text}
              {q.imageName && ` (${q.imageName})`}
            </p>
          ))}

          <Textarea
            className="w-100 mt-2 min-height-150px"
            placeholder="Ask anything..."
            onChange={(e) => setQuestionText(e.target.value)}
            value={questionText}
          />

          <p className="mt-2 semibold text-xs mb-0">Image name (optional)</p>

          <Input value={imageName || ''} onChange={(e: any) => setImageName(e.target.value)} type="text" />

          <div className="d-flex justify-content-end">
            <Button onClick={addQuestion} disabled={!questionText} color="secondary">
              Add question
            </Button>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button onClick={createCaseQuestion} disabled={!questions.length} className="mt-2 min-width-150px" color="primary">
            Create {questions.length ? pluralize('question', questions.length) : ''} {selectedVet ? `for ${selectedVet.label}` : ''}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
